import { flowRight } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { EXPERIMENT_INNER_NAVIGATION } from '@wix/communities-forum-client-commons';
import {
  hasAlreadyTriedToScroll,
  HeaderHeights,
  isNavigationInWixComments,
  NavigationScrollerWrapperProps,
  shouldSkipOnFirstTry,
} from '.';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getLocation } from '../../../common/store/location/location-selectors';
import withExperiment from '../../hoc/with-experiment';
import { RootState } from '../../types/store-types';
import { getFreemiumHeaderHeight } from '../../services/get-freemium-header-height';
import { getWebsiteFixedHeaderHeight } from '../../services/get-website-fixed-header-height';
import { isElementTopVisible } from '../../services/is-element-top-visible';
import { isFixedHeaderOpen } from '../../services/is-fixed-header-open';
import { isWebsiteFreemium } from '../../services/is-website-freemium';
import { scrollToElementTop } from '../../services/scroll-to-element-top';

const NavigationScroller = ({
  children,
  location,
  route,
  isInnerNavigationExperimentEnabled,
}: NavigationScrollerWrapperProps) => {
  const isFreemium = useMemo(() => isWebsiteFreemium(location.baseUrl as string), [location]);
  const forumRef = useRef<null | HTMLDivElement>(null);
  const heights = useRef<HeaderHeights | null>(null);
  const savedPath = useRef<string | null>(null);
  const getHeights = () => {
    return {
      freemiumHeaderHeight: getFreemiumHeaderHeight(),
      fixedHeaderHeight: getWebsiteFixedHeaderHeight(),
    };
  };
  useEffect(() => {
    if (
      !isInnerNavigationExperimentEnabled ||
      !forumRef.current ||
      hasAlreadyTriedToScroll(savedPath.current, location.pathname, location.prevPath) ||
      shouldSkipOnFirstTry(savedPath.current, location.pathname) ||
      isNavigationInWixComments(location.pathname, savedPath.current || '')
    ) {
      return;
    }
    if (!heights.current) {
      heights.current = getHeights();
    }
    savedPath.current = location.pathname;
    const { freemiumHeaderHeight, fixedHeaderHeight } = heights.current;

    const isWebsiteHeaderOpen = isFixedHeaderOpen();
    const isTopVisible = isElementTopVisible({
      element: forumRef.current,
      freemiumHeaderInfo: { isVisible: isFreemium, height: freemiumHeaderHeight },
      fixedHeaderInfo: { isVisible: isWebsiteHeaderOpen, height: fixedHeaderHeight },
    });
    if (isTopVisible) {
      return;
    }
    scrollToElementTop({
      element: forumRef.current,
      freemiumHeaderInfo: { isVisible: isFreemium, height: freemiumHeaderHeight },
      fixedHeaderInfo: { isOpen: isWebsiteHeaderOpen, height: fixedHeaderHeight },
    });
  }, [isFreemium, isInnerNavigationExperimentEnabled, location, route]);

  return (
    <div id="navigation-scroller" ref={forumRef}>
      {children}
    </div>
  );
};

const mapRuntimeToProps = (state: RootState) => {
  const location = getLocation(state);
  return {
    location,
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.LOCATION]),
  withExperiment({
    isInnerNavigationExperimentEnabled: EXPERIMENT_INNER_NAVIGATION,
  }),
)(NavigationScroller);
